import React, { useMemo } from "react";
import {
  CircleProgressIcon,
  Csku,
  Typography,
  colors,
} from "@commonsku/styles";
import {
  BASE_COLUMN_TYPES,
  ColumnDataMap,
  ORDER_TYPES,
  OrderType,
  REPORT_TOOLTIP_TARGET_ID,
} from "../config/types";
import { CellProps } from "./Cell";
import { useTooltipAttributes } from "../../../../hooks";
import styled from "styled-components";

const StatusCellContainer = styled(Csku)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .status-icon {
    margin-right: 8px;
    width: 40px;
    height: 40px;
  }

  .status-text {
    display: flex;
    flex-direction: column;
    line-height: 16px;
    font-weight: 400;
    color: ${colors.primary1.main};

    .order-type {
      font-size: 14px;
    }

    .order-status {
      font-size: 12px;
      padding: 0 !important;
    }
  }
`;

interface OrderStatusCircleProps {
  percentage: number;
  text: string;
  displayName: string;
}

const ORDER_TYPE_STATUS_MAP: Record<OrderType, OrderStatusCircleProps> = {
  [ORDER_TYPES.OPPORTUNITY]: {
    percentage: 20,
    text: "Op",
    displayName: "Opportunity",
  },
  [ORDER_TYPES.PRESENTATION]: {
    percentage: 40,
    text: "P",
    displayName: "Presentation",
  },
  [ORDER_TYPES.ESTIMATE]: {
    percentage: 60,
    text: "E",
    displayName: "Estimate",
  },
  [ORDER_TYPES["SALES ORDER"]]: {
    percentage: 80,
    text: "SO",
    displayName: "Sales Order",
  },
  [ORDER_TYPES.INVOICE]: {
    percentage: 100,
    text: "In",
    displayName: "Invoice",
  },
};

type TColumn = typeof BASE_COLUMN_TYPES.Status;
const StatusCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
}: CellProps<TColumn>) => {
  const { accessorKey, dataTransform } = column;

  const { order_type, form_number, status_name, href, filter_orders = [], tooltipText } = useMemo<
    ColumnDataMap<TColumn>
  >(() => {
    if (!dataTransform) throw new Error("StatusCell requires dataTransform");

    return { ...dataTransform(row.original[accessorKey]) };
  }, [accessorKey, dataTransform, row.original]);

  const { percentage, text, displayName } = ORDER_TYPE_STATUS_MAP[order_type];

  const tooltipAttributes = useTooltipAttributes(
    tooltipText ?? (
      filter_orders.length >= 2 ?
        filter_orders.map(
          o => `${ORDER_TYPE_STATUS_MAP[o.order_type].displayName}# ${o.form_number}`
        ).join("\n") :
        null
    ),
    REPORT_TOOLTIP_TARGET_ID
  );

  return (
    <StatusCellContainer {...tooltipAttributes}>
      <Csku
        className={className}
        as="a"
        href={href}
        target={"_blank"}
        style={style}
        onClick={onClick}
      >
        <CircleProgressIcon
          className="status-icon"
          percentage={percentage}
          text={text}
          strokeWidth={4}
          textStyle={{ fontSize: "14px", fontWeight: 600 }}
          strokeColor={colors.primary1.main}
          altText={`${displayName} (${form_number}) - ${status_name}`}
          size="small"
          showCheckMarkOnComplete
        />
        <Csku as="div" className="status-text">
          <Typography.Span
            className="order-type"
            color="var(--color-primary1-main)"
          >
            {`${displayName} (${form_number})`}
          </Typography.Span>
          <Typography.Span
            className="order-status"
            color="var(--color-primary1-main)"
          >
            {status_name}
          </Typography.Span>
        </Csku>
      </Csku>
      {filter_orders.length > 0 && (
        <Csku className="status-text" style={{ flexDirection: "row", gap: "2px" }}>
          {filter_orders.map(
            (order, index) => (
              <Csku
                key={order.href}
                as="a"
                href={order.href}
                target="_blank"
              >
                <Typography.Span
                  className="order-type"
                  color="var(--color-primary1-main)"
                >
                  {ORDER_TYPE_STATUS_MAP[order.order_type].displayName}# {order.form_number}
                  {index < filter_orders.length - 1 && ','}
                </Typography.Span>
              </Csku>
            )
          )}
        </Csku>
      )}
    </StatusCellContainer>
  );
};

export default StatusCell;
